import React from 'react';
import styled from 'styled-components'
import laptopCharacter from '../images/spring19/LaptopCharacter.png'
import emailCharacter from '../images/spring19/EmailCharacter.png'
import dangerCrewPeople from '../images/spring19/dangerCrewPeople.png'
import NewsletterSignup from './NewsletterSignup'

const AboutHeadline = styled.h2`
  display: flex;
  align-items: center;
`;

const AboutParagraph = styled.p`
    line-height: 1.5em;
    margin: 0 auto 1em;
`;

const ContentGrid = styled.div`
    
    padding-left: 1em;
    padding-right: 1em;  
    
  
    

    @media (min-width:900px) {
      display: flex;
      padding:0;
      align-items: flex-start;
      
      .column {
        width: 65%;
        padding-right: 4em;
      }
      
      .side {
        width: 35%;
      }
    }   
`;


export default function (props, state) {
  return (
    <ContentGrid style={{marginTop: "4em"}}>
      <div className="column">
        <AboutHeadline>
          <img style={{width: 64, marginRight: 5, marginLeft: -10 }} src={laptopCharacter} alt="Danger Crew Character" />
          <span>About Danger Crew</span>
        </AboutHeadline>
        <main>
          <AboutParagraph>Danger Crew is a classic single player RPG set in the world of programming and hackers.</AboutParagraph>
          <AboutParagraph>It's your first day of work as a lead engineer at Five Star Belts, the finest craftsmen belt company the city of Clayton has ever seen. Rumors have been spreading ever since a meeting with a nefarious vendor hit the calendars. The company might be in danger.</AboutParagraph>
          <AboutParagraph>Bust out your laptop and throw down against rival programmers in Hack Battles. Build up your Crew with a variety of skill-specialized software engineers. Deck out your computers with upgrades, stickers, and scripts. Help quirky strangers solve big problems. </AboutParagraph>
          <AboutParagraph>Created by web developers, Danger Crew is inspired by real world experiences while working as programmers at large software companies. Danger Crew is written entirely in web technologies: HTML, CSS, and JavaScript. </AboutParagraph>
        </main>
      </div>
      <div className="side">
          <AboutHeadline>
            <img style={{width: 64, marginRight: 5, marginLeft: -10}} src={emailCharacter} alt="Danger Crew Character" />
            <span>Join our Newsletter</span>
          </AboutHeadline>
        <AboutParagraph>Stay up to date with Danger Crew news, releases, and more! We'll email you when we have updates to share. No spam, ever.</AboutParagraph>
        <NewsletterSignup />
          <img style={{ display: "block", margin: "2em auto"}} src={dangerCrewPeople} alt="Characters from the Danger Crew" />
      </div>


      </ContentGrid>

  )
}