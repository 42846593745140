import React from 'react'
import Layout from '../components/Layout'
import '../components/home.css'
//import SaleBanner from '../components/SaleBanner'
import Header from '../components/Header'
import Footer from '../components/Footer'
import {Wrap} from '../components/grid'
import { HeroVideo } from '../components/HeroVideo'
import HomeAbout from '../components/HomeAbout'
import HomeAboutThisGame from '../components/HomeAboutThisGame'



const IndexPage = () => (
  <Layout>
    {/*<SaleBanner />*/}
    <Header/>

    <Wrap>
      <HeroVideo />
      <HomeAbout />
      <HomeAboutThisGame />
    </Wrap>
    <Footer />
  </Layout>
);

export default IndexPage
