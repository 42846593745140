import React from 'react'
import {Link} from 'gatsby'
import './Footer.css'
import { dangerCrewTwitter } from '../constants'


const Footer = () => (
  <footer className="Footer">
    <p>
      ©2021 The Danger Crew
      <a className="Footer__contact" href={dangerCrewTwitter} target="_blank" rel="noopener noreferrer">@dangercrewgame</a>
      <a className="Footer__contact" href="mailto:team@thedangercrew.com?subject=Message%20to%20Team%20Danger%20Crew">Email Us</a>
      <Link className="Footer__credits" to="/credits">Credits</Link>

    </p>
  </footer>
)

export default Footer
