import React from 'react';
import styled from 'styled-components'
import ChevronRightIcon from '../icons/ChevronRightIcon'
import { itchPageLink, steamPageLink } from '../constants'

const AboutHeadline = styled.h2`
  font-weight:normal;
  text-align: center;
  
  @media (max-width: 600px) {
    font-size: 1em;
    padding-left:10px;
    padding-right:10px;
  }
  
`;

// const AboutParagraph = styled.p`
//     line-height: 1.5em;
//     text-align: center;
//     padding-left: 1em;
//     padding-right: 1em;
//     max-width: 700px;
//     margin: 0 auto;
// `;

const ButtonContainer = styled.div`
    display: flex;
    margin: 0 auto 1em;
    justify-content: center;
    
    @media (max-width: 700px) {
      display: block;
      padding-left: 1em;
      padding-right: 1em;
    }
`
const MajorButtonLink = styled.a`
    position:relative;
    text-decoration:none;
    border-radius:3px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    padding-left: 2em;
    padding-right: 2em;
    
    font-weight:bold;
    font-size: 1.3em;
    color: #fff;
    
    margin-right: 10px;
    margin-bottom: 10px;
    
    color: #222034;
    border: 1px solid #222034;
    
    transition: background 0.1s, color 0.1s;
    
    
    
    &:hover {
      color: #fff;
      background: #222034;
    }
    
    .button_far-right {
      position:absolute;
      right:15px;
      top:0;
      bottom:0;
      display: flex;
      align-items: center;
      
    }
`;

export default function (props, state) {
  return (
    <div name="about">
      <AboutHeadline>Available Now on <strong>Windows</strong>, <strong>Mac</strong>, and <strong>Linux</strong>!</AboutHeadline>

      <ButtonContainer>
        <MajorButtonLink href={steamPageLink+"?icid=MainCta"} target="_blank" rel="noopener noreferrer">
          <span>Danger Crew on Steam</span>
          {/*<span className="button_far-right">*/}
            {/*<ChevronRightIcon />*/}
          {/*</span>*/}
        </MajorButtonLink>

        <MajorButtonLink href={itchPageLink+"?icid=MainItchCta"} target="_blank" rel="noopener noreferrer">
          <span>Danger Crew on itch.io</span>
          {/*<span className="button_far-right">*/}
            {/*<ChevronRightIcon />*/}
          {/*</span>*/}
        </MajorButtonLink>
      </ButtonContainer>

    </div>
  )
}