import React from 'react';
import steamLogoWhite from '../images/spring19/steamLogoWhite.png'
import itchLogoWhite from '../images/spring19/itchLogoWhite.svg'
import styled from 'styled-components'
import { steamPageLink, itchPageLink } from '../constants'

const TopCallouts = styled.div`

  position: absolute; 
  right:0px; 
  top:0px;
  padding: 10px;
 
  @media (max-width: 700px) {
    position: relative;
    right:0;
    top:0;
    border-radius:0;
    justify-content: center;
    margin-bottom: 0;
  }
  
  .TopCallout {
    display: flex;
    align-items: center;
    height: 60px;  
    background: #706D8A;
    border-bottom: 5px solid #595481;
    border-radius:4px;
    color: #fff;
    padding-left: 15px;
    padding-right: 15px;
    text-decoration:none;
    margin-bottom: 10px;
    &:hover {
      background: #646189;
    }
  }
  
  .TopCallout--itch {
    background: #fa5c5c;
    border-bottom: 5px solid #d61434;
    &:hover {
      background: #e24b4b;
    }
  }
  
  .TopCallout_vendor-logo {
    width: 40px;
    display: block;
    margin-right: 10px;
  }
`

export default () => (
  <TopCallouts>
    <a className={"TopCallout"} href={steamPageLink+"?icid=TopCallout"} target="_blank">
      <img src={steamLogoWhite} alt="Steam" className="TopCallout_vendor-logo" />
      <span>
        Buy on <strong>STEAM</strong>
      </span>
    </a>
    <a className={"TopCallout TopCallout--itch"} href={itchPageLink+"?icid=TopItchCallout"} target="_blank">
      <img src={itchLogoWhite} alt="Steam" className="TopCallout_vendor-logo" />
      <span>
        Buy on <strong>ITCH.IO</strong>
      </span>
    </a>
  </TopCallouts>
)