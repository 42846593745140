import styled from 'styled-components'

export const HideOnThinScreen = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`

export const Wrap = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 0px auto;
`
