import React from 'react'
import styled from 'styled-components'
//import {Wrap} from './grid'
//import emmyArrow from '../images/spring19/EmmyArrow.png'
import addToMailchimp from 'gatsby-plugin-mailchimp'

const NewsletterContainer = styled.div`
  
  h3 {
    font-size: 1.6em;
    margin: 0 0 0.5em 0;
  }
  p {
    color: #697078;
    line-height: 1.3em;
  }
  label {
    font-weight:bold;
    display:block;
    margin-bottom:3px;
  }
  
  
 h3 {
  text-align: center;
  font-weight:normal;
  strong {
    font-weight:strong;
  }
 }
   .Newsletter__SignupCopy {
      max-width: 413px;
      text-align:center;
  }
  
  
  form {
    margin: 0 auto;
  }
  
  
  .Newsletter__FormContainer {
    position: relative;
    &.hide {
      .Newsletter__TextInput,
      .Newsletter__SubmitButton {
        display:none;
      }
    }
  }
  .Newsletter__character-img {
    position:absolute;
    left: -225px;
    top:-106px;
    @media(max-width:760px) {
      display:none;
    }
  }
  
  .Newsletter__TextInput {
    height: 44px;
    display: block;
    margin-bottom: 5px;
    width: 100%;
    border: 1px solid #979797;
    border-radius:3px;
    padding-left:10px;
    
    &::placeholder {
      color: #B6B6B6;
    }
  }
  
  .Newsletter__SubmitButton {
    border:0;
    display: block;
    width: 100%;
    height: 44px;
    background: #4496F7;
    border-bottom: 3px solid #1E6BCC;
    border-radius:3px;
    height: 47px;
    font-weight:bold;
    color: #fff;
    transition: background 0.2s;
    text-align:center;
    cursor:pointer;
    
    &:hover {
      background: #3588E8;
    }
  }
  
  .Newsletter__FormSuccess {
    display: block;
    color: #00B710;
    font-weight:bold;
    &.hide {
      display:none;
    }
  }
    
 
`


const NewsletterSignup = () => (
  <NewsletterContainer>
        <form onSubmit={handleSubmitNewsletterForm}>
          <label style={{animation: "glow 1s infinite alternate-reverse"}} htmlFor="email-input">Email Address</label>
          <div className="Newsletter__FormContainer">
            <input className="Newsletter__TextInput" id="email-input" type="email" placeholder="you@email.com" />
            <input className="Newsletter__SubmitButton" type="submit" />
          </div>
          <div className="Newsletter__FormSuccess hide">
            Thanks for signing up!
          </div>
        </form>
  </NewsletterContainer>
)
export default NewsletterSignup;


function handleSubmitNewsletterForm(e) {
  if (e) {
    e.preventDefault();
  }

  //https://thedangercrew.us13.list-manage.com/subscribe/post
  //POST

  const email = document.querySelector("#email-input").value;

  if (email.length > 0) {
    //sign up
    //see docs (https://www.gatsbyjs.org/packages/gatsby-plugin-mailchimp/)
    addToMailchimp(email) // listFields are optional if you are only capturing the email address.
      .then(data => {
        // but you can do whatever you want (including ignoring this `then()` altogether)
        document.querySelector('.Newsletter__FormSuccess').classList.remove("hide");
        document.querySelector('.Newsletter__FormContainer').classList.add("hide");
      })
      .catch(() => {
        // unnecessary because Mailchimp only ever
        // returns a 200 status code
      })
  }
}