import React from 'react'
import styled from 'styled-components'

const HeroVideoContainer = styled.div`
  display: block;
  margin: 0 auto;
  width: 100%;
`
export const HeroVideo = () => {
  return (
    <HeroVideoContainer>
      <iframe title="Danger Crew Trailer on YouTube" style={{width: "100%"}} width="900" height="506" src="https://www.youtube.com/embed/ej1OAujypd8?rel=0" frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
      {/*<div style={{*/}
      {/*//emulates the video*/}
      {/*width: 1120,*/}
      {/*paddingBottom: "56%",*/}
      {/*maxWidth: "100%",*/}
      {/*background: "#1a0a2b",*/}
      {/*}}>*/}
      {/*</div>*/}
    </HeroVideoContainer>
  )
}
