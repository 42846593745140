import React from 'react'
import headerlogo from '../images/spring19/headerlogo.png'
import { BackgroundShape } from './BackgroundShape'
//import {HideOnThinScreen} from './grid'
import MainNavigation from './MainNavigation'
import SteamTopCallout from './TopCallouts'

const Header = () => (
  <header style={{position: "relative"}}>
      <SteamTopCallout />

    <img
      style={{
        display: "block",
        margin: "0 auto",
        maxWidth: "100%",
        paddingTop: 20
      }}
      src={headerlogo}
      alt="Danger Crew"
    />
    {/*<HideOnThinScreen>*/}
      {/*<svg*/}
        {/*style={{*/}
          {/*display: "block",*/}
          {/*width: 80 * 5, //5 = pixel size of pixel art assets*/}
          {/*margin: "0 auto",*/}
          {/*marginBottom: "2em"*/}
        {/*}}*/}
        {/*xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 80 5" shapeRendering="crispEdges">*/}
        {/*<path stroke="#1d1946" d="M0 0h3m3 0h2m2 0h3m1 0h1m1 0h1m1 0h3m1 0h1m3 0h3m1 0h3m1 0h3m1 0h3m4 0h3m1 0h2m2 0h1m1 0h1m1 0h3m1 0h2m2 0h3m1 0h1m1 0h1m1 0h3m1 0h3M0 1h1m1 0h1m3 0h1m1 0h1m1 0h1m3 0h1m1 0h1m1 0h1m3 0h1m3 0h1m1 0h1m1 0h1m1 0h1m1 0h1m3 0h1m1 0h1m4 0h1m1 0h1m1 0h1m1 0h1m1 0h1m1 0h1m1 0h1m3 0h1m1 0h1m2 0h1m2 0h1m1 0h1m1 0h1m1 0h1m1 0h1M0 2h3m3 0h1m1 0h1m1 0h2m2 0h1m1 0h1m1 0h2m2 0h1m3 0h1m1 0h1m1 0h3m1 0h2m2 0h2m5 0h3m1 0h1m1 0h1m1 0h1m1 0h1m1 0h2m2 0h1m1 0h1m2 0h1m2 0h1m1 0h1m1 0h2m2 0h2M0 3h1m1 0h1m3 0h1m1 0h1m1 0h1m3 0h1m1 0h1m1 0h1m3 0h1m3 0h1m1 0h1m1 0h1m3 0h1m3 0h1m1 0h1m4 0h1m1 0h1m1 0h1m1 0h1m1 0h1m1 0h1m1 0h1m3 0h1m1 0h1m2 0h1m2 0h1m1 0h1m1 0h1m1 0h1m1 0h1M0 4h1m1 0h1m3 0h2m2 0h3m2 0h1m2 0h3m1 0h3m1 0h3m1 0h1m3 0h3m1 0h1m1 0h1m4 0h1m1 0h1m1 0h2m3 0h1m2 0h3m1 0h1m1 0h1m2 0h1m2 0h3m1 0h1m1 0h1m1 0h3"/>*/}
      {/*</svg>*/}
    {/*</HideOnThinScreen>*/}
    <MainNavigation />
    <BackgroundShape />
  </header>
);

export default Header
