import React from 'react'
import styled from 'styled-components'
import starbackground from '../images/spring19/starbackground1.png'

const BackgroundGradient = styled.div`
  width: 100%;
  height: 50vw;
  max-height:800px;
  background-image: linear-gradient(-180deg, #CAEAFF 0%, #FFFFFF 100%);
  left:0;
  top:0;
  position: absolute;
  z-index:-1;
`
const BackgroundStars = styled.div`
  position: absolute;
  left:0; top:0; bottom:0; right: 0;
  background-image: url(${starbackground});
  background-position: center center;
  background-repeat: repeat no-repeat;
  opacity: 0.4;
`


export const BackgroundShape = () => {
  return (
    <BackgroundGradient>
      <BackgroundStars />
    </BackgroundGradient>
  )
}