import React from 'react';
//import { Link } from 'gatsby'
import {mailchimpSignupLink, pressKitLink} from "../constants";
import styled from 'styled-components';

const Navigation = styled.nav`
  display: flex;
  justify-content: center;
  margin-bottom: 1em;
  
  a {
    font-weight:bold;
    padding: 10px 20px;
    margin-left: 10px;
    margin-right: 10px;
    color: inherit;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  
  @media (max-width:500px) {   
    .hide-mobile {
      display: none;
    }
  }
`


export default function (props, state) {
  return (
      <Navigation>
        <a className="hide-mobile" href={"https://play.thedangercrew.com"} target="_blank" rel="noopener noreferrer">Play Demo</a>
        <a className="hide-mobile" href="#about">About</a>
        <a href={pressKitLink} target="_blank" rel="noopener noreferrer">Press Kit</a>
        <a href={mailchimpSignupLink} target="_blank" rel="noopener noreferrer">Newsletter</a>
      </Navigation>
  )
}